import React, { useState } from "react";
import { Link } from "react-router-dom";
import Path from "../routes/Path";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <>
      <div className="flex flex-row justify-center lg:justify-start xl:justify-start md:justify-start lg:flex-row xl:flex-row md-flex-row mx-4 gap-3 items-center p-2">
        <a href="tel:+917674040233">
          <i className="fas fa-phone me-2" />
          +91-7674040233
        </a>
        <span className="hidden lg:block md:block xl:block">|</span>
        <a
          className="hidden lg:block md:block xl:block"
          href="tel:+917389640233"
        >
          <i className="fas fa-phone me-2" />
          +91-7389640233
        </a>
        <span className="hidden lg:block md:block xl:block">|</span>
        <a
          href="mailto:hr@mindcoders.in"
          target="_blank"
          className="hidden lg:block md:block xl:block"
        >
          hr@mindcoders.in
        </a>
      </div>
      <hr />
      <header className="sticky top-0 z-[999]">
        <nav className="w-screen shadow-lg bg-white bg-opacity-50 backdrop-blur-md z-50">
          <div className="flex justify-between items-center p-3">
            <div className="flex items-center">
              <Link to="/" className="flex items-center">
                <img
                  src={`${window.location.origin.toString()}/assets/img/mindcoderlogo.webp`}
                  alt="logo"
                  className="h-12"
                  loading="lazy"
                />
              </Link>
            </div>

            <div className="lg:hidden flex items-center">
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <i className="fa fa-bars"></i>
              </button>
            </div>

            <div className="hidden lg:flex flex-grow justify-end">
              <ul className="flex space-x-4 text-gray-700 font-bold">
                <li>
                  <Link to="/" className="hover:text-blue-600">
                    Home
                  </Link>
                </li>

                <li className="relative group">
                  {/* <span className="text-[#e80000!important] absolute bottom-6 right-0 text-sm">New</span> */}
                  <Link
                    to="/courses"
                    className="flex items-center hover:text-blue-600"
                  >
                    <sup className="text-[#e80000!important] text-sm animate-bounce">
                      New
                    </sup>
                    Courses <i className="fa fa-chevron-down ml-1 "></i>
                  </Link>
                  <div className="absolute border bg-slate-100 -left-72 z-50 hidden w-[800px] rounded-xl shadow-lg group-hover:block">
                    <div className="p-4">
                      <h6 className="font-bold mb-2">Popular Courses</h6>
                      <div className="grid grid-cols-3">
                        <Link
                          to={Path.COURSE_DSA_MASTER}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-regular fa-file-code"></i>
                          </span>{" "}
                          DSA with C/C++
                          <sup className="text-[#091f81] animate-ping">
                            <i className="fa-solid fa-circle"></i>
                          </sup>
                        </Link>
                        <Link
                          to={Path.COURSE_MERN}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-brands fa-node-js"></i>
                          </span>{" "}
                          MERN Stack
                        </Link>
                        <Link
                          to={Path.COURSE_DATA_ANALITICS}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-solid fa-chart-line"></i>
                          </span>{" "}
                          Data Analytics
                        </Link>
                        <Link
                          to={Path.COURSE_REACT}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-brands fa-react"></i>
                          </span>{" "}
                          React JS
                        </Link>
                        <Link
                          to={Path.COURSE_FRONTEND}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-solid fa-code"></i>
                          </span>{" "}
                          Frontend
                        </Link>
                        <Link
                          to={Path.COURSE_DATA_SCIENCE}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-solid fa-chart-pie"></i>
                          </span>{" "}
                          Data Science
                        </Link>
                        <Link
                          to={Path.COURSE_DSA}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-regular fa-file-code"></i>
                          </span>{" "}
                          DSA
                        </Link>
                        <Link
                          to={Path.COURSE_BACKEND}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-solid fa-server"></i>
                          </span>{" "}
                          Backend
                        </Link>
                        <Link
                          to={Path.COURSE_DSA_ADD_FULLSTACK}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-solid fa-code"></i>
                          </span>{" "}
                          DSA & Fullstack
                          <sup className="text-[#091f81] animate-ping">
                            <i className="fa-solid fa-circle"></i>
                          </sup>
                        </Link>
                        <Link
                          to={Path.COURSE_PYTHON}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-brands fa-python"></i>
                          </span>{" "}
                          Python Programming
                        </Link>

                        <Link
                          to={Path.COURSE_PYTHON_FULLSTACK}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-brands fa-python"></i>
                          </span>{" "}
                          Python Fullstack
                        </Link>
                        <Link
                          to={Path.COURSE_DIGITALMARKETING}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-solid fa-rectangle-ad"></i>
                          </span>{" "}
                          Digital Marketing
                        </Link>
                        <Link
                          to={Path.COURSE_JAVA}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-brands fa-java"></i>
                          </span>{" "}
                          Java Programming
                        </Link>
                        <Link
                          to={Path.COURSE_JAVA_FULLSTACK}
                          className="block py-2 px-4 hover:bg-gray-100"
                        >
                          <span className="me-2">
                            <i className="fa-brands fa-java"></i>
                          </span>{" "}
                          Java Fullstack
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <Link
                    to={Path.SERVICES}
                    className="flex items-center hover:text-blue-600"
                  >
                    Services
                  </Link>
                </li>

                <li>
                  <Link to={Path.JOBS} className="hover:text-blue-600">
                    Job Portal
                  </Link>
                </li>

                <li className="relative group">
                  {/* <Link
                    to="/courses"
                    className="flex items-center hover:text-blue-600"
                  > */}
                  Resources <i className="fa fa-chevron-down ml-1"></i>
                  {/* </Link> */}
                  <div className="absolute -left-20 z-50 hidden w-[250px] rounded-xl bg-white shadow-lg group-hover:block">
                    <div className="p-4">
                      <Link
                        to={Path.ABOUT}
                        className="block py-2 px-4 hover:bg-gray-100 hover:ml-2"
                      >
                        <span className="me-2">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>{" "}
                        About Us
                      </Link>
                      <Link
                        to={Path.PLACEMENT}
                        className="block py-2 px-4 hover:bg-gray-100 hover:ml-2"
                      >
                        <span className="me-2">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>{" "}
                        Placements
                      </Link>
                      <Link
                        to={Path.INTERNSHIP}
                        className="block py-2 px-4 hover:bg-gray-100 hover:ml-2"
                      >
                        <span className="me-2">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>{" "}
                        Internship
                      </Link>
                    </div>
                  </div>
                </li>

                <li>
                  <Link to={Path.CONTACT} className="hover:text-blue-600">
                    Contact Us
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://lms.mindcoders.in/login"
                    target="_blank"
                    className="bg-[#05314b] text-white px-4 py-2 rounded-tr-2xl rounded-bl-2xl hover:rounded-tr-none hover:rounded-bl-none hover:rounded-br-2xl hover:rounded-tl-2xl"
                  >
                    Login
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>

        {/* Offcanvas Menu Start */}
        <div
          className={`fixed inset-0 z-50 bg-black bg-opacity-50 lg:hidden transition-opacity duration-300 ${
            isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
          id="offcanvasWithBackdrop"
        >
          <div
            className={`fixed inset-y-0 w-[100%]  max-w-xs bg-white shadow-lg transition-transform duration-300 ${
              isMenuOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="p-4 flex items-center justify-between">
              <Link to="/" className="flex items-center">
                <img
                  src={`${window.location.origin.toString()}/assets/img/mindcoderlogo.webp`}
                  alt="logo"
                  className="h-[70px]"
                  loading="lazy"
                />
              </Link>
              <button
                type="button"
                className="text-gray-600"
                onClick={() => setIsMenuOpen(false)}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="px-4 text-xl">
              <ul className="">
                <li>
                  <Link
                    to="/"
                    onClick={() => setIsMenuOpen(false)}
                    className="block text-gray-700 hover:text-blue-600"
                  >
                    Home
                  </Link>
                </li>

                <li className="relative group">
                  <Link
                    to="/courses"
                    onClick={() => setIsMenuOpen(false)}
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    Courses
                    <sup className="text-[#e80000!important] font-semibold text-sm animate-ping">
                      New
                    </sup>
                  </Link>
                </li>
                <li>
                  <Link
                    to={Path.SERVICES}
                    onClick={() => setIsMenuOpen(false)}
                    className="block text-gray-700 hover:text-blue-600"
                  >
                    Services
                  </Link>
                </li>

                <li>
                  <Link
                    to="/jobs"
                    onClick={() => setIsMenuOpen(false)}
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    Job Portal
                  </Link>
                </li>

                <li className="relative">
                  <div
                    onClick={() => {
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                    className="block text-gray-700 items-center hover:text-blue-600 cursor-pointer"
                  >
                    Resources <i className="fa mt-1 fa-chevron-down ml-1"></i>
                  </div>
                  <ul
                    className={`rounded-xl bg-white shadow-lg transition-all ease-in ${
                      isDropdownOpen
                        ? "opacity-100 max-h-screen"
                        : "opacity-0 max-h-0"
                    } overflow-hidden`}
                  >
                    <li className="block transition-all py-2 px-4">
                      <Link
                        to={Path.ABOUT}
                        onClick={() => {
                          setIsDropdownOpen(!isDropdownOpen);
                          setIsMenuOpen(false);
                        }}
                        className="block py-2 hover:bg-gray-100 hover:ml-2"
                      >
                        <span className="me-2">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>{" "}
                        About Us
                      </Link>
                    </li>
                    <li className="block transition-all px-4">
                      <Link
                        to={Path.PLACEMENT}
                        onClick={() => {
                          setIsDropdownOpen(!isDropdownOpen);
                          setIsMenuOpen(false);
                        }}
                        className="block hover:bg-gray-100 hover:ml-2"
                      >
                        <span className="me-2">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>{" "}
                        Placements
                      </Link>
                    </li>
                    <li className="block transition-all py-2 px-4">
                      <Link
                        to={Path.INTERNSHIP}
                        onClick={() => {
                          setIsDropdownOpen(!isDropdownOpen);
                          setIsMenuOpen(false);
                        }}
                        className="block py-2 hover:bg-gray-100 hover:ml-2"
                      >
                        <span className="me-2">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>{" "}
                        Internship
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    to="/contact"
                    onClick={() => setIsMenuOpen(false)}
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    Contact Us
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://lms.mindcoders.in/login"
                    target="_blank"
                    className="block py-2 px-4 bg-blue-600 text-white rounded"
                  >
                    Login
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        {/* Offcanvas Menu End */}
      </header>
    </>
  );
};

export default NavBar;
