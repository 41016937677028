import WordRotate from "./magicui/text-animation.tsx";

export function AnimatedText() {
  return (
    <WordRotate
      className="text-xl md:text-2xl lg:text-5xl font-bold text-[#172554] dark:text-white"
      words={["Authorized by", "Licensed by"]}
    />
  );
}
