import React from "react";
import { AnimatedText } from "./AnimatedText";
import { baseUrl } from "../helpers/baseUrl";

const Authorized = () => {
  return (
    <section>
      <hr />
      <div className="w-full justify-around container py-3 md:py-5 items-center flex flex-col md:flex-row">
        <div className="w-full md:w-1/3 flex justify-center">
          <AnimatedText />
        </div>
        <div className="w-full md:w-1/3 flex justify-center">
          <img
            className="h-[100px] md:h-[150px] lg:h-[200px]"
            src={`${baseUrl()}/assets/img/msme.png`}
            alt="msme"
          />
        </div>
        <div className="w-full md:w-1/3 flex justify-center">
          <img
            className="h-[100px] md:h-[150px] lg:h-[300px]"
            src={`${baseUrl()}/assets/img/ncfse.jpg`}
            alt="ncfse"
          />
        </div>
      </div>
    </section>
  );
};

export default Authorized;
